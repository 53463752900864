import React from 'react';
import { List, Spoiler, ThemeIcon } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import ajisafe from '../../assets/images/departments/ortho/ajisafe.jpg';
import alabi from '../../assets/images/departments/ortho/alabi.jpg';
import ganiyat from '../../assets/images/departments/ortho/ganiyat.jpg';
import george from '../../assets/images/departments/ortho/george.jpg';
// import mabel from '../../assets/images/departments/ortho/mabel.jpg';
import makinde from '../../assets/images/departments/ortho/makinde.jpg';
import moyosore from '../../assets/images/departments/ortho/moyosore.jpg';
import team1 from '../../assets/images/team-1-1.jpg';
import olasode from '../../assets/images/departments/ortho/olasode.jpg';
import omoniyi from '../../assets/images/departments/ortho/omoniyi.jpg';
import olofinlade from '../../assets/images/departments/ortho/olofinlade.jpeg';
import oni from '../../assets/images/departments/ortho/oni.jpg';
import salawu from '../../assets/images/departments/ortho/salawu.jpg';
import vitoseji from '../../assets/images/departments/ortho/vitoseji.jpg';

const Orthopaedics = () => {
  const services = [
    {
      name: 'Arthroplasty services',
    },
    {
      name: 'Spine services',
    },
    {
      name: 'Pediatric Orthopaedics services',
    },
    {
      name: 'Orthopaedic Oncology services',
    },
    {
      name: 'Arthroscopy & sports medicine services',
    },
    {
      name: 'Complex Trauma surgery services',
    },
    {
      name: 'Adult and paediatric reconstruction surgery',
    },
    {
      name: 'Orthosis and prosthetic services',
    },
    {
      name: 'Occupational therapy services',
    },
    {
      name:
        'Application of various forms of plastering required in orthopaedics and trauma',
    },
  ];

  const subs = [
    {
      name: 'Arthroplasty',
    },
    {
      name: 'Spine',
    },
    {
      name: 'Pediatric Orthopaedics',
    },
    {
      name: 'Orthopaedic Oncology',
    },
    {
      name: 'Arthroscopy & sports medicine',
    },
    {
      name: 'Complex Trauma surgery',
    },
    {
      name: 'Adult and paediatric reconstruction surgery',
    },
    {
      name: 'Surgical Accident & Emergency',
    },
  ];

  const anc = [
    {
      name: 'Plaster unit',
    },
    {
      name: 'Orthosis and Prosthetic  unit',
    },
    {
      name: 'Occupational therapy unit',
    },
  ];

  return (
    <Layout pageTitle='Department of Orthopaedics And Traumatology | LUTH'>
      <NavOne />
      <PageHeader title='Department of Orthopaedics And Traumatology' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Orthopaedics And Traumatology
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        The Lagos University Teaching Hospital (LUTH) was
                        established in 1962 by the Federal Government of Nigeria
                        (Lagos University Teaching Hospital Act 1961). <br />
                        <br /> The Department of Orthopaedic and Traumatology
                        LUTH was created out of the Department of Surgery, on
                        24th January, 2014. The Department offer services in all
                        aspects of Orthopaedics and Trauma.
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Services'>
                    <p className='course-details__tab-text'>
                      We offer services in all our subspecialties including:
                    </p>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {services.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                    <h2 className='blog-one__title' style={{ marginTop: 20 }}>
                      Subspecialties
                    </h2>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {subs.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                    <h2 className='blog-one__title' style={{ marginTop: 20 }}>
                      Ancillary Services
                    </h2>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {anc.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={george} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. George O. Enweluzo</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant Orthopaedic and Trauma Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BSc. (HONS), MBBS, FMCS, FWACS, FMCOrtho, FICS.
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Orthopaedic Oncology, Arthroplasty, arthroscopy,
                            minimal access Orthopaedics, paediatric Orthopaedics
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Olakunle O. Badmus</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant Orthopaedic and Trauma Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FWACS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Spine, Adult reconstruction, Orthopaedic oncology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={alabi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Eyitayo. O. Alabi</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant Orthopaedic and Trauma Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBChB, FMCS(Ortho), FMCOrtho
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Arthroplasty, Arthroscopy & minimal access
                            Orthopaedics, sport medicine
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olasode} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Olasode .I. Akinmokun</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant Orthopaedic and Trauma Surgeon
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            MBBS, FWACS, FMCOrtho
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Paediatric Orthopaedic, Arthroplasty, Limb Deformity
                            correction, Limb reconstruction, complex trauma
                          </p>
                        </div>
                      </div>
                      <h2 className='blog-one__title' style={{ marginTop: 20 }}>
                        Occupational Therapists
                      </h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={moyosore} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mrs. Sheriff-Balogun Moyosore A.</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Principal in Occupational Therapist/Head of Unit
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            Diploma in Occupational Therapy, B. Edu Guidance and
                            Counselling
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={oni} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mr. Ayooluwa Oni O.</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Principal in Occupational Therapist
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            Diploma & B.Sc. Occupational Therapy
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={salawu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mr. Salawu Olalekan Waliyilahi</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Principal in Occupational Therapist
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            Diploma in Occupational Therapy, B.Sc. Psychology
                            (in View)
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ganiyat} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mrs. Adedayo Ganiyat A.</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Principal in Occupational Therapy
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            Diploma in Occupational Therapy, B.Sc. Environmental
                            Health (in View)
                          </p>
                        </div>
                      </div>

                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={omoniyi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mrs. Omoniyi Omoleye Abosede</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Clerical Officer
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            Chartered in Purchasing & Supply Management, B.Sc.
                            Business Administration (in View)
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olofinlade} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Olofinlade Taiwo Olugbenga</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Post-Senior porter
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            SSCE, HND Acc
                          </p>
                        </div>
                      </div>
                      <h2 className='blog-one__title' style={{ marginTop: 20 }}>
                      Plaster Technicians
                      </h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ajisafe} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mr. Ajisafe A. Tahjudeen</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                            Plaster Technician
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            Diploma in Plaster Casting
                          </p>
                        </div>
                      </div>
                      {/* <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Ebenezer A. Ajokolo</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                            Senior Registrar I
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BChD
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Oral & maxillofacial Surgery
                          </p>
                        </div>
                      </div> */}
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={vitoseji} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mr. Vitoseji Aina. S.</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                            Plaster Technician
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            National Diploma in Plaster Casting
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Miss. Olawepo Gbemisola A.</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Plaster Technician
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            National Diploma in Cast Technology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={makinde} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mr. Makinde Abraham A.</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Plaster Technician
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            National Diploma in Cast Technology
                          </p>
                        </div>
                      </div>
                      <h2 className='blog-one__title' style={{ marginTop: 20 }}>
                      Prosthetists and Orthotists
                      </h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Miss. Alademehin Teniola O.</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Prosthetics & Orthotics/Head of Unit
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            National Diploma in Prosthetics & Orthotics (O.N.D),
                            B.Sc. Computer Science (In View)
                          </p>
                        </div>
                      </div>
                      {/* <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Benjamin U. Ewanosetin</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Oral & maxillofacial surgery
                          </p>
                        </div>
                      </div> */}
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mr. Olubokun Yemi</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                            Executive Officer Prosthetics & Orthotics
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            National Diploma Business Study Prosthetics &
                            Orthotics
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mr. Oloyede Hakeem</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Assistant craft man
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            S.S.C.E
                          </p>
                        </div>
                      </div>
                      <h2 className='blog-one__title' style={{ marginTop: 20 }}>
                      Administrative Staffs
                      </h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Miss. Omubo-Dede Mabel</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Executive Officer
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            HND, M.Sc. Business Administration
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Miss. Hogan Ronke Esther</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Clerical Officer
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            Chartered in Purchasing & Supply Management
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mrs. Adebambo Oluwabunmi Shola </h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Senior Data Processing
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            Diploma in Computer, NABTEB 120WPM, B.Sc. Computer
                            Science
                          </p>
                        </div>
                      </div>
                      <h2 className='blog-one__title' style={{ marginTop: 20 }}>
                        Consultants
                      </h2>
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:orthopaedics@luth.gov.ng'>
                          orthopaedics@luth.gov.ng
                        </a>
                      </p>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default Orthopaedics;
